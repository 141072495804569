import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const title = ({children, backgroundImage,title, tags}) => {
    return (
        <section className={`node__header ${(backgroundImage)?'node__header--backgroundImage' : ''}`} style={(backgroundImage) ? {backgroundImage: `url(${backgroundImage})`}: {}}>
            <div className="grid-container">
                <div className="node__title">
                    <h1>
                        {title}
                    </h1>
                </div>
                { (tags) && <div className="node__tags">
                    {
                        tags.map((tag, key) => {
                            return <div className="field-item" key={key}>
                                <Link to={tag.entity.entityUrl.path}>{tag.entity.entityLabel}</Link>
                            </div>
                        })
                    }
                </div>
                }
            </div>
        </section>
    )
}

title.propTypes = {
  children: PropTypes.object,
  backgroundImage:PropTypes.string,
  title: PropTypes.string.isRequired,
  tags: PropTypes.array
}

export default title;
