import React, { useState } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Navigation from './navigation'
import Header from './header'
import classNames from 'classnames'
import CookieConsent from '../CookieConsent/cookieConsent'
import '../../../../sj_verzekeringen/dist/assets/css/app.css';

const layout = ({children,container,classNameMain}) => {

    const [isMobile, setIsMobile] = useState(false);

    const mainClasses = classNames({
        'grid-container': container
    },classNameMain)

    const onShowMobile = (state) => {
        setIsMobile(state);
    }

    const mobileMenuClasses = classNames({
        'mobile-open': isMobile,
        'site__nav': true
    })

    return(
        <StaticQuery
            query={graphql`{
            drupal{
                main: menuByName(name: "main"){
                    name
                    links{
                        label
                        url{
                            path
                        }
                    }
                }
                footer: menuByName(name: "footer"){
                    name
                    links{
                        label
                        url{
                            path
                        }
                    }
                }
            }
            site{
                siteMetadata{
                    logo
                    brokom
                }
            }
        }`}
            render={data => (
                <React.Fragment>
                  <Header
                    classes={mobileMenuClasses}
                  >
                    <div className="top-bar--header">
                      <div className="grid-container">
                        <div className="top-bar">
                          <div className="top-bar-left">
                            <ul className="menu large-horizontal">
                              <li>
                                <img src={data.site.siteMetadata.logo} className="site__logo" alt="SJ Verzekeringen"/>
                              </li>
                            </ul>
                          </div>
                          <div className="top-bar-right">
                            <img src={data.site.siteMetadata.brokom} width="292" height="138" alt="Brokom Makelaar"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Navigation
                      menuLinks={data.drupal.main}
                      type="main"
                      onShowMobile={onShowMobile}
                    />
                  </Header>
                    {
                        !isMobile && <main className={mainClasses}>
                            {children}
                        </main>
                    }
                    {
                        !isMobile && <footer className="footer bgcolor-gray">
                            <div className="footer__logo">
                                <img src={data.site.siteMetadata.logo} className="site__logo" alt="SJ Verzekeringen"/>
                            </div>
                            <div className="footer__container">
                                <div className="grid-container">
                                    <div className="footer__navigation">
                                        <Navigation
                                            menuLinks={data.drupal.footer}
                                            type="footer"
                                        />
                                    </div>
                                    <div class="footer__credit">Hoevensebaan 196 - bus 2 - 2950 Kapellen | OR 0751783256 - Antwerpen</div>
                                    <div className="footer__credit">
                                        Ontwerp & ontwikkeling door <a href="http://gdvwebsolutions.be" target="_blank"  rel="noopener noreferrer">GDV Web Solutions</a> | Copyright 2019 SJ Verzekeringen
                                    </div>
                                </div>
                            </div>
                        </footer>
                    }
                    <CookieConsent
                      declineButtonText={'Sluiten'}
                      cookieName={'accept_cookies'}
                      buttonText={'Begrepen!'}
                    >
                      <span>Dankzij die cookies kunnen we jouw surfervaring verbeteren. Lees meer over de cookies die we plaatsen in onze <strong><Link to="/privacybeleid" target="_blank">&nbsp;cookie policy</Link></strong></span>
                    </CookieConsent>
                </React.Fragment>
            )}
        />
    )
}

export default layout
