import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'


const Button = ({ title, href, onClick, target, children, classes, styles }) => {

  const classObject = ClassNames(classes,'button');

  return (
    <a
      href={href}
      onClick={onClick}
      title={title}
      target={target}
      className={classObject}
      style={{...styles}}
    >
      {children}
    </a>
  )
}

Button.defaultProps = {
  target: '_self'
}
Button.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.oneOf([
    '_blank',
    '_parent',
    '_self',
    '_top',
  ]),
  styles: PropTypes.object
}

export default Button;
