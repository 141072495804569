import React from 'react'
import { Link } from 'gatsby'

export default ({breadcrumb,container}) => (
    breadcrumb && <div className={(container) ? 'grid-container' : ''}>
        <ul
            className="breadcrumbs"
        >
            {
                breadcrumb.map((crumb, index) => {
                    return <li
                        key={index}
                    >
                        {(index !== breadcrumb.length -1) ?
                            <Link to={crumb.url.path}> {crumb.text} </Link>
                            : <span> {crumb.text} </span>
                        }
                    </li>
                })
            }
        </ul>
    </div>
)
