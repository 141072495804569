import React from 'react'

const header = ({children, classes}) => (
    <React.Fragment>
        <nav role="navigation" className={classes} data-sticky-container>
            <div className="sticky" data-sticky data-margin-top="0">
                {children}
            </div>
        </nav>
    </React.Fragment>
)

export default header;
