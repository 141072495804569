import React, {useState, useEffect} from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

export default ({ type, menuLinks, onShowMobile }) => {

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const mobileBreakpoint = 1200;

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
        onShowMobile(!mobileMenuOpen);
    }

    const triggerMobileMenu = () => {
        setShowMobileMenu(document.body.offsetWidth < mobileBreakpoint)
    }

    const clickMenuItem = () => {
        if(document.body.offsetWidth < mobileBreakpoint){
            toggleMobileMenu()
        }
    }

    useEffect(() => {
        window.addEventListener('resize',() => {
            triggerMobileMenu()
        });

        triggerMobileMenu()
    });

    if (typeof window !== "undefined") {
        require("smooth-scroll")('a[href*="#"]')
    }

    if(type === "main"){
        return (
            <div className={`top-bar--main`}>
              <div className="grid-container">
                <div className="top-bar">
                    {
                        showMobileMenu &&
                        <div className="top-bar-left site__nav-trigger">
                            <ul className="menu" onClick={toggleMobileMenu}>
                                <li>
                                    <button id="burger" className="site__nav-mobile-trigger">
                                        <span className="burger"></span>
                                        <span className="burger-text">Menu</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    }
                    <div className="top-bar-right">
                        <ul className="menu">
                            <li>
                                <div className="site__phone">
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        style={{width: '1rem', height:'1rem'}}
                                    />
                                    <a href="tel:003236652418">+32 (0)36 652 418</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={`top-bar-left main-nav site__nav-menu ${(mobileMenuOpen) ? 'is-open':''}`} id="main-nav">
                      <ul id="overlay-nav-menu" className="overlay-nav-menu menu vertical large-horizontal">
                        {(!showMobileMenu ||mobileMenuOpen) && menuLinks.links.map((link, key) => {
                          return (
                            <li key={key}>
                              <Link
                                to={link.url.path}
                                onClick={clickMenuItem}
                              >{link.label}</Link>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                </div>
              </div>
            </div>
        )
    }else if(type === "footer"){
        return(
            <div className="footer__navigation">
                <ul className="menu align-center vertical large-horizontal">
                    {menuLinks.links.map((link, key) => {
                        return (
                            <li key={key}>
                                <Link
                                    to={link.url.path}
                                >{link.label}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }else{
        return(
            <ul id="overlay-nav-menu" className="overlay-nav-menu menu vertical large-horizontal">
                {menuLinks.links.map((link, key) => {
                    return (
                        <li key={key}>
                            <Link
                                to={link.url.path}
                            >{link.label}</Link>
                        </li>
                    )
                })}
            </ul>
        )
    }
}
