import React from 'react'
import PropTypes from "prop-types"
import Layout from '../components/Layout/layout'
import { Helmet } from 'react-helmet'
import Title from '../components/Node/node_title'
import BreadCrumb from '../components/Layout/breadCrumb'
import AdminPanel from '../components/Admin'
import Markdown from "react-markdown";
import { sanitizeUrl } from '../helpers/'

const NodeArticle = ({pageContext, preview }) => {
    const page = pageContext.article;
    const breadcrumb = page.entityUrl.breadcrumb;

    return(
        <React.Fragment>
            <Helmet>
                <title>{page.title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta name="MobileOptimized" content="width"/>
                {page.entityMetatags.map((metatag,key) => {
                    if(metatag.__typename === 'Default_MetaProperty'){
                        return <meta key={key} property={metatag.key} content={sanitizeUrl(metatag.value,'cms.')}/>
                    }else if(metatag.__typename === 'Default_MetaLink'){
                        return <link key={key} rel={metatag.key} href={sanitizeUrl(metatag.value,'cms.')}/>
                    }else{
                        return <meta key={key} name={metatag.key} content={sanitizeUrl(metatag.value,'cms.')}/>
                    }
                })}
                {page.fieldImage.url && <meta property="og:image" content={page.fieldImage.url} />}
                {page.fieldImage.url && <link rel="image_src" href={page.fieldImage.url} />}
            </Helmet>
            <Layout>
                <Title
                    backgroundImage={page.fieldImage.url}
                    tags={page.fieldTags}
                    title={page.title}
                />
                <div className="node__body">
                  {preview &&
                    <AdminPanel
                      adminUrl={'https://cms.sjverzekeringen.be/node/'}
                      nodeId={page.entityId}
                    />
                  }
                  <BreadCrumb
                      breadcrumb={breadcrumb}
                      container
                  />
                  {page.body !== null &&
                    <Markdown
                      className={'grid-container'}
                      source={ page.body.processed }
                      escapeHtml={false}
                    />
                  }
                </div>
            </Layout>

        </React.Fragment>
    )
}

NodeArticle.defaultProps = {
  preview: false
}

NodeArticle.propTypes = {
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export default NodeArticle

