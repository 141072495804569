import React, { useState, useEffect } from  'react';
import Cookies from "js-cookie";
import PropTypes from 'prop-types'

const CookieConsent = ({
  cookieName,
  cookieValue = 1,
  declineCookieValue = 0,
  debug = false,
  expires = 150,
  extraCookieOptions,
  hideOnAccept = true,
  hideOnDecline = true,
  children,
  enableDeclineButton = false,
  declineButtonText = 'Sluiten',
  buttonText = 'Aanvaarden'
}) => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if(Cookies.get(cookieName) === undefined || debug){
      setVisible(true)
    }
  },[])

  const accept = () => {
    Cookies.set(cookieName,cookieValue, {expires: expires, ...extraCookieOptions})
    hideOnAccept && setVisible(false);
  }

  const decline = () => {
    Cookies.set(cookieName, declineCookieValue, {expires: expires, ...extraCookieOptions})
    hideOnDecline && setVisible(false)
  }

  if(visible){
    return (
      <div className={`cookieconsent`}>
        <div className={`grid-container fluid`}>
          <div className={`grid-x`}>
            <div className={`cookieconsent__description cell medium-10`}>
              {children}
            </div>
            <div className={`cookieconsent__buttongroups cell medium-2`}>
              <button
                onClick={accept}
                className={`cookieconsent__button cookieconsent__button--accept`}
              >
                {buttonText}
              </button>
              {
                enableDeclineButton && <button
                  onClick={decline}
                  className={`cookieconsent__button cookieconsent__button--decline`}
                >
                  {declineButtonText}
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null
}

CookieConsent.propTypes = {
  cookieName: PropTypes.string.isRequired,
  cookieValue: PropTypes.string,
  declineCookieValue: PropTypes.string,
  debug: PropTypes.string,
  expires: PropTypes.number,
  extraCookieOptions: PropTypes.object,
  hideOnAccept: PropTypes.bool,
  hideOnDecline: PropTypes.bool,
  enableDeclineButton: PropTypes.bool,
  declineButtonText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
}
export default CookieConsent
