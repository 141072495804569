import React from 'react'
import PropTypes from 'prop-types'
import Button from "../Layout/button";

const AdminPanel = ({ container, showEdit, showDelete, showRevisions, adminUrl, nodeId }) => {
  return (
      <div className={`${container ? 'grid-container':''}`}>
        {showEdit && <Button href={`${adminUrl}${nodeId}/edit`} >Bewerken</Button>}
        {showDelete && <Button href={`${adminUrl}${nodeId}/delete`} >Verwijderen</Button>}
        {showRevisions && <Button href={`${adminUrl}${nodeId}/revisions`}>Revisies</Button>}
      </div>
  )
}
AdminPanel.defaultProps = {
  container: true,
  showEdit: true,
  showDelete: true,
  showRevisions: true
}
AdminPanel.propTypes = {
  container: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  showRevisions: PropTypes.bool,
  adminUrl: PropTypes.string.isRequired,
  nodeId: PropTypes.string.isRequired
}

export default AdminPanel
